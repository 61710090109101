import { useEffect } from 'react';
import SpecialCode from './Pricing';
import changeTitle from '../../utitls/changeTitle';
import FeaturesComponent from './FeaturesComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { APIFeatures, APIPriceFeatures } from '../../utitls/APIList';

const Price = () => {

  const APIID = useParams().id
  const navigate = useNavigate()

  useEffect(() => {
    if (!APIFeatures[APIID] || !APIPriceFeatures[APIID]) {
      return navigate("/")
    }
  }, [APIID])
  

  changeTitle("Features and Pricing | ScoreSwift")

  return (
    <div className="pb-5">
    <div className="mx-auto md:px-8 pt-24 px-4">
        <h2 className="text-center font-display text-3xl font-bold tracking-tight text-primary md:text-4xl">
          Features
        </h2>
        <FeaturesComponent APIID={APIID}/>
      </div>
      <div id="features" className="mx-auto mt-8 max-w-6xl">
        <h2 className="text-center font-display text-3xl font-bold tracking-tight text-primary md:text-4xl">
        Pricing
        </h2>
        <SpecialCode APIID={APIID}/>
      </div>
    </div>
  )
};

export default Price;
