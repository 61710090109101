const Services = () => {
    return (<div className=" px-2 py-10">
        <div id="features" className="mx-auto max-w-6xl">
            <p className="text-center text-base font-semibold leading-7 text-primary-500">
                Features
            </p>
            <h2 className="text-center font-display text-3xl font-bold tracking-tight text-primary md:text-4xl">
                Features which never been missed
            </h2>
            <ul className="mt-16 grid grid-cols-1 gap-6 text-center text-white md:grid-cols-3">
                <li className="rounded-xl hover:shadow-2xl shadow-xl bg-secondaryBlue px-6 py-8 ">
                    <img
                        src="https://www.svgrepo.com/show/530438/ddos-protection.svg"
                        alt=""
                        className="mx-auto h-10 w-10"
                    />
                    <h3 className="my-3 font-display text-primary font-medium">Real-Time Sports Insights</h3>
                    <p className="mt-1.5 text-sm leading-6 text-white">
                    Gain instant access to live scores, game statistics, and player performance data across various sports genres, including cricket, football, and basketball. Stay ahead of the competition with up-to-the-minute insights.
                    </p>
                </li>
                <li className="rounded-xl bg-secondaryBlue px-6 py-8 hover:shadow-2xl shadow-xl">
                    <img
                        src="https://www.svgrepo.com/show/530442/port-detection.svg"
                        alt=""
                        className="mx-auto h-10 w-10"
                    />
                    <h3 className="my-3 font-display text-primary font-medium">Seamless Streaming Solutions</h3>
                    <p className="mt-1.5 text-sm leading-6 text-white">
                    Elevate user experience with seamless live streaming capabilities for sporting events. Whether it's for broadcasting live matches or integrating highlights, our streaming solutions ensure uninterrupted enjoyment for your audience.
                    </p>
                </li>
                <li className="rounded-xl bg-secondaryBlue px-6 py-8 hover:shadow-2xl shadow-xl">
                    <img
                        src="https://www.svgrepo.com/show/530444/availability.svg"
                        alt=""
                        className="mx-auto h-10 w-10"
                    />
                    <h3 className="my-3 font-display text-primary font-medium">Odds Score Integration</h3>
                    <p className="mt-1.5 text-sm leading-6 text-white">
                    Integrate real-time odds scores into your applications to provide users with valuable betting insights. Enhance engagement by offering up-to-date odds information for informed decision-making.
                    </p>
                </li>
                <li className="rounded-xl bg-secondaryBlue px-6 py-8 hover:shadow-2xl shadow-xl">

                    <img
                        src="https://www.svgrepo.com/show/530440/machine-vision.svg"
                        alt=""
                        className="mx-auto h-10 w-10"
                    />
                    <h3 className="my-3 font-display text-primary font-medium group-hover:text-primary-500">
                    Low-Latency API Performance
                    </h3>
                    <p className="mt-1.5 text-sm leading-6 text-white">
                    Experience lightning-fast response times with our low-latency APIs, designed to deliver data instantly. Ensure your applications provide a seamless user experience with our high-performance API solutions.
                    </p>

                </li>
                <li className="rounded-xl bg-secondaryBlue px-6 py-8 hover:shadow-2xl shadow-xl">

                    <img
                        src="https://www.svgrepo.com/show/530450/page-analysis.svg"
                        alt=""
                        className="mx-auto h-10 w-10"
                    />
                    <h3 className="my-3 font-display text-primary font-medium group-hover:text-primary-500">
                    High-Performance API Solutions
                    </h3>
                    <p className="mt-1.5 text-sm leading-6 text-white">
                    Harness the power of our APIs engineered for maximum efficiency, reliability, and scalability, providing your applications with unmatched performance capabilities.
                    </p>

                </li>
                <li className="rounded-xl bg-secondaryBlue px-6 py-8 hover:shadow-2xl shadow-xl">

                    <img
                        src="https://www.svgrepo.com/show/530453/mail-reception.svg"
                        alt=""
                        className="mx-auto h-10 w-10"
                    />
                    <h3 className="my-3 font-display text-primary font-medium group-hover:text-primary-500">
                    Fantasy Point Tracking
                    </h3>
                    <p className="mt-1.5 text-sm leading-6 text-white">
                    Fantasy sports enthusiasts to track player performances and calculate fantasy points in real-time. Our tracking system ensures accuracy and reliability, enhancing the excitement of fantasy sports competitions.
                    </p>

                </li>
            </ul>
        </div>
        <div></div>
    </div>

    )
}

export default Services