import changeTitle from '../../utitls/changeTitle'
import { APIList } from '../../utitls/APIList'
import { Link } from 'react-router-dom'

const AllDocs = () => {

    changeTitle("Docs | ScoreSwift")
    return (
        <div className=" py-6 sm:py-8 lg:py-12">
            <div className="mx-auto max-w-screen-2xl py-10 px-4 md:px-8">
                {/* text - start */}
                <div className="mb-10 md:mb-16">
                    <h2 className="mb-4 text-center text-2xl font-bold text-primary md:mb-6 lg:text-3xl">
                        Documentation
                    </h2>
                    <p className="mx-auto max-w-screen-md text-center text-white md:text-lg">
                        We have prepared all the documentation on <b className='text-primary'> <a href='https://rapidapi.com'>RapidAPI</a></b> because they have better UI/UX of documentation along with code snippets.
                    </p>
                </div>
                <div className="grid gap-5 sm:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:grid-cols-4 xl:gap-8">
                    {
                        APIList.map((api, key5) => (
                            // article - start
                            <div key={key5} className="flex flex-col overflow-hidden rounded-lg bg-secondaryBlue">
                                <a target='_blank'
                                    href={api.docs}
                                    className="group relative block h-48 overflow-hidden md:h-64"
                                >
                                    <img
                                        src={api.image_url}
                                        loading="lazy"
                                        alt="api logo"
                                        className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
                                    />
                                </a>
                                <div className="flex flex-1 flex-col p-4 sm:p-6">
                                    <h2 className="mb-2 text-lg font-semibold text-primary">
                                        <a target='_blank'
                                            href={api.docs}
                                            className="transition duration-100 hover:text-indigo-500 active:text-indigo-600"
                                        >
                                            {api.name}
                                        </a>
                                    </h2>
                                    <p className="mb-8 text-white ">
                                        {

                                            api.description.length < 130 ? api.description :
                                                api.description.substring(0, 130) + "....."
                                        }
                                    </p>
                                    <div className="mt-auto flex items-end justify-between">
                                        <a target='_blank'
                                            href={api.docs}
                                            className="w-full p-2 text-sm px-10 rounded-full font-medium lg:block transition-all hover:scale-90 active:scale-90 bg-primary text-bg_theme text-center"
                                        >
                                            Documentation
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>

    )
}

export default AllDocs