import { FaPhoneSquareAlt } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { FaInstagram, FaFacebook } from "react-icons/fa6";

const ContactButton = () => {
  return (
    <div className='w-[40px] hidden md:flex fixed top-1/2 md:top-1/3 py-4 rounded-md left-0 justify-center items-center flex-col gap-4 text-white z-50'>
      <a className="text-xl pl-2 hover:bg-primary rounded-r-full py-1 w-full h-full duration-200" rel="noreferrer"  target="_blank" href="https://wa.me/+16616750322?text=hello">
        <FaPhoneSquareAlt />
      </a>
      <a className="text-xl pl-2 hover:bg-primary rounded-r-full py-1 w-full h-full duration-200" rel="noreferrer"  target="_blank" href="https://x.com/scoreswiftofficial">
        <RiTwitterXFill />
      </a>
     
      <a className="text-xl pl-2 hover:bg-primary rounded-r-full py-1 w-full h-full duration-200" rel="noreferrer"  target="_blank" href="https://facebook.com/scoreswiftofficial">
        <FaFacebook />
      </a>
      <a className="text-xl pl-2 hover:bg-primary rounded-r-full py-1 w-full h-full duration-200" rel="noreferrer" target="_blank" href="https://instagram.com/scoreswiftofficial">
        <FaInstagram />
      </a>
     
    </div>
  )
}

export default ContactButton