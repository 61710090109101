import { APIFeatures } from '../../utitls/APIList';

const FeaturesComponent = ({APIID}) => {

  return (
    <>
      <ul className="mt-16 grid grid-cols-1 gap-6 text-center text-white md:grid-cols-3">
        {APIFeatures[APIID]?.map((feature) => (
          <li key={feature.heading} className="rounded-xl hover:shadow-2xl shadow-xl bg-secondaryBlue px-6 py-8 ">
            <img
              src={feature.img}
              alt={feature.img}
              className="mx-auto h-10 w-10"
            />
            <h3 className="my-3 font-display text-primary font-medium">{feature.heading}</h3>
            <p className="mt-1.5 text-sm leading-6 text-white">{feature.description}</p>
          </li>
        ))}
      </ul>
    </>)
}

export default FeaturesComponent