import config from '../../utitls/index.json'

const Features = () => {
  const { features } = config;
  const { subtitle, description, items: featuresList } = features;
  return (
    <div className={`py-12 bg-background`} id="features">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          
          <p className="my-4 max-w-2xl text-xl text-white lg:mx-auto">
            {subtitle}
          </p>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-primary sm:text-4xl">
            {description}
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {featuresList.map((feature) => (
              <div className='hover:shadow-2xl shadow-xl bg-secondaryBlue rounded-md p-2 py-3' key={feature.name}>
                
                  <div
                    className={`absolute flex items-center justify-center h-20 w-14 rounded-md text-white`}
                  >
                    <img
                      className={`inline-block h-9 w-9 rounded-full`}
                      src={feature.logo}
                      alt={"feature.name"}
                    />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-primary">
                    {feature.name}
                  </p>
                
                <dd className="mt-2 ml-16 text-base text-white">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Features;