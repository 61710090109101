import { Link } from "react-router-dom";

const Project = ({api}) => {

  return (
    // article - start
    <div className="flex flex-col overflow-hidden rounded-lg bg-secondaryBlue">
      <Link
        to={api.slug}
        className="group relative block h-48 overflow-hidden md:h-64"
      >
        <img
          src={api.image_url}
          loading="lazy"
          alt="api logo"
          className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
        />
      </Link>
      <div className="flex flex-1 flex-col p-4 sm:p-6">
        <h2 className="mb-2 text-lg font-semibold text-primary">
          <Link
            to={api.slug}
            className="transition duration-100 hover:text-indigo-500 active:text-indigo-600"
          >
            {api.name}
          </Link>
        </h2>
        <p className="mb-8 text-white ">
          {

            api.description.length < 130 ? api.description :
              api.description.substring(0, 130) + "....."
          }
        </p>
        <div className="mt-auto flex items-end justify-between">
          <Link
            to={api.slug}
            className="w-full p-2 text-sm px-10 rounded-full font-medium lg:block transition-all hover:scale-90 active:scale-90 bg-primary text-bg_theme text-center"
          >
            Features
          </Link>
        </div>
      </div>
    </div>
    // article - end
  );
};

export default Project;
