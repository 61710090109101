import { Link} from 'react-router-dom'
import { APIPriceFeatures } from '../../utitls/APIList';

const SpecialCode = ({APIID}) => {

    return (
        <section class="p-4 md:p-8">
            <div class=" max-w-screen-xl lg:py-8">
                <div class="space-y-8 text-white lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
                    {
                        APIPriceFeatures[APIID]?.map((api, key) => (
                            <div onClick={() => console.log(api)} key={key}
                                class="flex flex-col p-6 mx-auto max-w-md text-center rounded-box shadow-xl xl:p-8  border-non hover:border-1 hover:border-primary transition hover:shadow-primary-focus/20 bg-secondaryBlue md:min-w-[270px]">
                                <h3 class="mb-4 text-2xl font-semibold">{api.api_type}</h3>

                                <div class="flex justify-center items-baseline mb-9 text-primary">
                                    <span class="mr-2 text-3xl font-extrabold">{
                                        Intl.DateTimeFormat().resolvedOptions().timeZone === "Asia/Calcutta" ? `₹ ${api.price_inr}` : `$ ${api.price_usd}`
                                    } </span>
                                    <span class="">/month</span>
                                </div>
                                <div role="list" class="mb-8 space-y-4 text-left">
                                    {
                                        api.avaialble_features.map((liElements, key2) => (
                                            <li key={key2} class="flex items-center space-x-3">
                                                <svg class="w-6 h-6 text-green-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                    fill="currentColor" viewBox="0 0 20 20">
                                                    <path
                                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z">
                                                    </path>
                                                </svg>
                                                <span class="text-base-content/80 text-white">{liElements}</span>
                                            </li>
                                        ))
                                    }
                                    {
                                        api.missing_features.map((liElements, key2) => (
                                            <li class="flex items-center space-x-3" key={key2}>
                                                <svg class="w-6 h-6 text-error" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                    fill="currentColor" viewBox="0 0 20 20">
                                                    <path
                                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z">
                                                    </path>
                                                </svg>
                                                <span class="text-base-content/30">{liElements}</span>
                                            </li>
                                        ))
                                    }

                                </div>
                                <Link target='_blank' to={api.link} className='p-2 text-sm px-10 rounded-full font-medium lg:block transition-all active:scale-90 bg-primary text-bg_theme hover:shadow-2xl hover:scale-90'>{api.button}</Link>
                                <Link target='_blank' to={api.documentation} className='p-2 text-sm px-10 rounded-full font-medium lg:block transition-all active:scale-90 bg-red-500 text-bg_theme hover:shadow-2xl hover:scale-90 mt-3'>Documentation</Link>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default SpecialCode