import Project from './Project'
import changeTitle from '../../utitls/changeTitle'
import { APIList } from '../../utitls/APIList'

const Projects = () => {
  changeTitle("APIs | ScoreSwift")
  return (
    <div className=" py-6 sm:py-8 lg:py-12">
      <div className="mx-auto max-w-screen-2xl py-10 px-4 md:px-8">
        {/* text - start */}
        <div className="mb-10 md:mb-16">
          <h2 className="mb-4 text-center text-2xl font-bold text-primary md:mb-6 lg:text-3xl">
            APIs
          </h2>
          <p className="mx-auto max-w-screen-md text-center text-white md:text-lg">
            These are the APIs which we offer right now. We are working hard to add more
          </p>
        </div>
        <div className="grid gap-5 sm:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:grid-cols-4 xl:gap-8">
          {
            APIList.map((api, key5) => (
              <Project api={api} key={key5}/>
            ))
          }
        </div>
      </div>
    </div>

  )
}

export default Projects